import React, { Component } from "react";
import { connect } from "react-redux"
import { compose } from "redux"
import { Dropdown, Button } from "antd"
import { FiLogOut, FiLock } from "react-icons/fi";

import ZitronIcon from "components/Icon";

import WithForgotPassword from "./actions";
import { getItem, clearItem } from "utils/tokenStore";
import { setUserProfile } from "reducers/profile";
import impersonateLogOut from "actions/impersonateLogOut"

class Userbox extends Component {
  componentDidMount() {
    this.props.getNotifications();
  }

  render = () => {
    const { profile } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.ImpersonateProfileReducer
      : this.props.data.ProfileReducer;

    const items = [
      { key: '0', label: (
        <div className="custom-header-user">
          <ZitronIcon antIcon="UserOutlined" style={{ cursor: 'pointer', fontSize: "30px" }} />
          <div className="d-flex flex-column justify-content-center">
            <span style={{color: "#344054"}}>{`${profile?.user?.first_name ?? ""} ${profile?.user?.last_name ?? ""}`}</span>
            <span style={{fontWeight: 300, color: "#667085"}}>{`${profile?.user?.email}`}</span>
          </div>
        </div>
      ) },
      { key: '1', label: (
        <div onClick={() => this.props.history.push(window.location.href.includes("/admin-impersonate") 
          ? "/admin-impersonate/dashboard/change_password" 
          : "/dashboard/change_password")}>
          <FiLock className="mr-2" /> Change Password
        </div>
      ) },
      { key: 'w', label: (
        <div onClick={() => Promise.all([
            clearItem("ZITRON_ACCESS_TOKEN"),
            clearItem("ZITRON_REFRESH_TOKEN"),
            clearItem("ZITRON_PERMISSION_TOKEN"),
            clearItem("ZITRON_IMPERSONATE_ACCESS_TOKEN"),
            clearItem("ZITRON_IMPERSONATE_PERMISSION_TOKEN"),
            this.props.setUserProfile({})])
          .then(() => {
            this.props.history.push("/login");
        })}>
          <FiLogOut className="mr-2" /> Log Out
        </div>
      ) }
    ]

    // Remove bell notifications list
    // const notifications = this.props.notifications?.map((notification) =>  ({
    //     key: `notifiaction-${notification.id}`,
    //     onClick: () => {
    //       if (notification.action?.redirect_link) {
    //         this.props.history.push(notification.action.redirect_link);
    //       } else {
    //         return
    //       }
    //     },
    //     label: (
    //       <div key={`notification-${notification.id}`} className="d-flex flex-column ml-2">
    //         <span className="font-weight-bold">{notification.title}</span>
    //         <span className="text-sm text-ligh-grey">{notification.message}</span>
    //       </div>
    //     )
    // }))
    // ?? [{
    //   key: "notification-0",
    //   icon: <ZitronIcon antIcon="BellOutlined" style={{ fontSize: "20px", color: "#1890ff" }} />,
    //   label: (
    //     <div className="d-flex flex-column ml-2">
    //       <span className="font-weight-bold">No Notification</span>
    //       <span className="text-sm text-ligh-grey">You have no new notification</span>
    //     </div>
    //   )
    // }]

    return (
      <>
        {getItem("ZITRON_IMPERSONATE_ACCESS_TOKEN") && window.location.href.includes("/admin-impersonate") && (
          <div className="d-flex align-items-center gap-2 mr-4">
            <span className="font-weight-heavy">Impersonation Mode</span>
            <Button
              type="primary"
              danger={true}
              style={{ backgroundColor: "#cb171a" }}
              onClick={() => {
                if (window.location.href.includes("/admin-impersonate")) {
                  this.props.impersonateLogOut();
                  setTimeout(() => {
                    window.close();
                  }, 500);
                }
              }}
            >
              Stop Impersonate
            </Button>
          </div>
        )}
        <div
          className="d-flex align-items-center mr-4"
          style={{ cursor: "pointer" }}
          onClick={() => this.props.history.push("/dashboard/main?moduleName=Approval Notifications")}>
          <ZitronIcon antIcon="BellOutlined" style={{ fontSize: "18px" }} />
        </div>
        <Dropdown menu={{ items: items }} trigger={["click"]} placement="bottomLeft">
          <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
            <ZitronIcon antIcon="UserOutlined" style={{ fontSize: "18px" }} className="mr-2" />
            <span style={{fontSize: "15px", fontWeight: "600"}}>{`${profile?.user?.first_name ?? ""} ${profile?.user?.last_name ?? ""}`}</span>
          </div>
        </Dropdown>
      </>
    );
  };
}

export default compose(
  connect(null, { setUserProfile, impersonateLogOut }), 
  WithForgotPassword
)(Userbox);
